import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Divider, ClickAwayListener } from '@mui/material';
import { navigationBarStyles as classes } from './styleObjects/NavigationBar';
import { TopMicroMenu } from './NavPanelComponents/TopMicroMenu';
import { MainMenu } from './NavPanelComponents/MainMenu';
import { useAtom, useSetAtom } from 'jotai';
import { isAuthenticatedAtom } from '@atoms/authentication';
import { navHeightAtom, remSizeAtom } from '@atoms/appSettings';
import { ProgressScrollBar } from '@components/SectionComponents/ProgressScrollBar';

export function NavbarV1(): JSX.Element {
    const [remSize] = useAtom(remSizeAtom);
    const setNavHeight = useSetAtom(navHeightAtom);
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const menuNav = useRef<HTMLDivElement>(null);
    const [isAuthenticated] = useAtom(isAuthenticatedAtom);

    useEffect(() => {
        if (menuNav.current) {
            setNavHeight(menuNav.current.clientHeight);
        }
    }, [setNavHeight]);

    return (
        <div ref={menuNav} css={classes.stickyNavbar} style={remSize >= 14 ? { position: 'relative' } : undefined}>
            <ClickAwayListener
                onClickAway={() => {
                    setSelectedMenuItem('');
                }}
            >
                <AppBar css={classes.header} id='NavigationBar' aria-label='Main navigation'>
                    <TopMicroMenu formPopoutOpen={false} userIsAuthenticated={isAuthenticated} />
                    <MainMenu
                        userIsAuthenticated={isAuthenticated}
                        selectedItem={selectedMenuItem}
                        setSelectedItem={setSelectedMenuItem}
                    />
                </AppBar>
            </ClickAwayListener>
            <Divider css={{ opacity: 0.6 }} />
            <ProgressScrollBar />
        </div>
    );
}
